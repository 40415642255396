<template>
    <v-card>
        <v-card-title class="title-post">
            <span>{{ formTitle }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text v-if="!isOnlyView">
            <div class="mx-3">
                <!--              Info help   -->
                <div
                    style="cursor: pointer"
                    class="grey--text mt-3 mb-5"
                    @click="overlayVideo = true"
                >
                    <v-btn
                        style="float: right"
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        outlined
                    >
                        <v-icon dark>
                            mdi-information-variant
                        </v-icon>
                    </v-btn>
                    <span> {{ $t('recognitions.help') }}</span>
                </div>
            </div>

            <!-- Select the person-->
            <div class="my-8">
                <select-person
                    :person="editedItem.recognizedUserId.value + ''"
                    @update:person="editedItem.recognizedUserId.value = $event"
                />
            </div>

            <!--          Recognition Type-->
            <div class="mx-3 mb-8">
                <span class="text-subtitle"> {{ $t('recognitions.selectType') }}</span>
                <v-select
                    v-model="editedItem.typeRecognition.value"
                    class="pt-0"
                    :items="typesRecognitions"
                    :item-text="`name_${getLang}`"
                    item-value="id"
                    persistent-hint
                    single-line
                    hide-details
                    :label="$t('recognitions.selectType')"
                />
            </div>

            <!--           Text Editor-->
            <visualEditor
                :type-editor="1"
                :body="editedItem.body.value"
                :words-to-select="wordsToSelect"
                @isValid="isValid = $event"
                @update:body="editedItem.body.value = $event"
            />
        </v-card-text>
        <v-card-text v-else>
            <!--        is only view-->
            <recognitionSingle
                :item="item"
                :is-only-view="true"
            />
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                text
                @click="$router.go(-1)"
            >
                {{ !isOnlyView ? $t('common.confirmDialogCancel') : $t('common.endProcess') }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="primary"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>

        <overlay-full-screen-video
            :overlay="overlayVideo"
            poster="https://back-itpaerovaluesawards.com/videos/mov_bbb-poster.jpeg"
            @update:overlay="overlayVideo = $event"
        >
            <source
                :src="`https://back-itpaerovaluesawards.com/videos/paso4_reconocimiento_${getLang}.mp4`"
                type="video/mp4"
            >
        </overlay-full-screen-video>
    </v-card>
</template>

<script>
import axios from '@/plugins/axios'
import typesRecognitions from '@/data/typesRecognitions'

import recognitionSingle from '@/components/recognitions/recognitionSingle'

import OverlayFullScreenVideo from "@/components/common/OverlayFullScreenVideo";
/* import rules from '@/helpers/rulesValidate'
import { mapGetters } from 'vuex' */

import visualEditor from '@/components/recognitions/form/visualEditor'

import selectPerson from '@/components/recognitions/selectPerson'

import postMixin from '@/mixins/postMixin'
import { mapGetters } from "vuex";

export default {
    name: 'PurposeCreate',
    components: { visualEditor, recognitionSingle, selectPerson, OverlayFullScreenVideo },
    mixins: [postMixin],
    data: function () {
        return {
            urlMain: '/purposes',
            /* rules: {
                ...rules
            }, */
            item: null,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,
            /* editorCustomToolbar: '#toolbar', */
            editorSettings: {
                modules: {
                    toolbar: '#toolbar',
                    imageDrop: true,
                    imageResize: {}

                }
            },
            dialogWords: false,
            wordsToSelect: [],
            typeSelected: 1,
            quill: {},
            typesRecognitions: typesRecognitions,
            typesRecognitionsSelected: typesRecognitions[0],
            overlayVideo: false
        }
    },
    computed: {
        ...mapGetters("languages", ['getLang']),
        formTitle() {
            if (this.item) {
                return this.isOnlyView ? this.$t('recognitions.view.title') : this.$t('recognitions.update.title')
            } else return this.$t('recognitions.create.title')
        },
        ...mapGetters("languages", ['getLang'])
        // ...mapGetters(['role', 'cities'])
    },
    created() {
        const itemTemp = this.$route.params?.item;

        /* if (this.$route.params?.isPurposeCloud) {
            this.urlMain = "/purposesCloud"
        } */

        if (itemTemp) {
            this.item = JSON.parse(itemTemp);
            this.editedItem = this.getEditedItem();
        }

        // this.fetchWords();
    },
    mounted() {
        // console.log('******* this.getImages ****');

        if (this.urlMain === "/purposesCloud" && !this.item) {
            this.$router.go(-1);
        }
    },
    methods: {

        getEditedItem() {
            const item = this.item;

            var empty = !item

            return {
                id: empty ? '' : item.id,
                /* title: {
                    value: empty ? '' : item.attributes.title,
                    error: ''
                }, */
                body: {
                    value: empty ? '' : item.attributes.body,
                    error: ''
                },
                recognizedUserId: {
                    value: empty ? '' : item.attributes.recognized_user_id,
                    error: ''
                },
                typeRecognition: {
                    value: empty ? '' : item.attributes.type_recognition,
                    error: ''
                },
                lang: {
                    value: empty ? undefined : item.attributes.lang,
                    error: ''
                }
            }
        },
        initialize() {

        },
        /* async fetchWords() {
            try {
                const words = await axios
                    .get('words')

                this.wordsToSelect = words.data.data;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        }, */
        async save() {
            // *************** Validation ************
            if (!this.editedItem.typeRecognition.value) {
                this.$store.dispatch('snackbar/error', this.$t('recognitions.validate.requireType'));
                return;
            }
            if (!this.editedItem.recognizedUserId.value) {
                this.$store.dispatch('snackbar/error', this.$t('recognitions.validate.requirePerson'));
                return;
            }

            const requireText = this.generalHelper.stripTags(this.editedItem.body.value);
            /* console.log('this.editedItem.body.value', this.editedItem.body.value)
            console.log('requireText', requireText) */
            if (requireText.length < 40) {
                this.$store.dispatch('snackbar/error', this.$t('recognitions.validate.requireText'));
                return;
            }
            // *******************************

            this.$store.dispatch('loading/start');

            var dataSubmit = {
                data: {
                    type: this.urlMain.substring(1),
                    attributes: {
                        body: this.editedItem.body.value,
                        recognized_user_id: this.editedItem.recognizedUserId.value,
                        type_recognition: this.editedItem.typeRecognition.value,
                        lang: this.editedItem.lang.value ?? this.getLang,
                        order: 100
                    }
                }
            };

            let urlAppend = ''
            let method = 'post'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                dataSubmit.data.id = '' + this.editedItem.id

                method = 'patch'
            }

            urlAppend += '?include=recognized_user';

            try {
                const result = await axios({
                    method: method,
                    url: this.urlMain + urlAppend,
                    data: dataSubmit
                });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.$store.dispatch('loading/end');
                    this.viewPurpose(result.data)

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                this.$store.dispatch('loading/end');

                let messageType = 0;
                if (error?.response?.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            if (e.source.pointer.startsWith('/data/attributes/body')) {
                                // this.editedItem.body.error = e.detail;

                                messageType = 1;
                            }
                        })
                    }
                }

                if (messageType) {
                    let errorText = '';
                    if (this.urlMain === "/purposesCloud") {
                        errorText = this.$t('purposes.mustBeSimilar');
                    } else {
                        errorText = this.$t('purposes.requiredBodies');
                    }

                    this.$store.dispatch('snackbar/error', errorText);
                } else if (error.response.status !== 423) { // Error 423 is that the competition close and is managed globally
                    this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
                }
            }
        },
        viewPurpose(item) {
            this.item = item.data;
            this.item.userData = item.included[0];

            this.isOnlyView = true;

            /* this.editedItem = {
                id: item.id,
                body: {
                    value: item.attributes.body,
                    error: ''
                }
            } */
        }
    }
}
</script>
