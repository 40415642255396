<template>
    <v-container>
        <v-form
            v-model="isValid"
            class="py-0"
        >
            <div>
                <span
                    style="display: block"
                    class="text-subtitle mb-3"
                > {{ $t('recognitions.createText') }}</span>
                <!--                          :editor-toolbar="editorCustomToolbar"-->
                <vue-editor
                    :id="typeEditor.toString()"
                    :key="typeEditor.toString()"
                    v-model="body_"
                    :editor-options="editorSettings"
                    :editor-toolbar="customToolbar"
                    class="editorPurpose"
                    @focus="focusQuill($event)"
                    @blur="focusQuill($event, false)"
                />

                <!--
                    <div class="grey--text mt-3">
                        <span v-html="$t('purposes.canUseWordsPredefined')" />
                    </div>

                                        <transition-group
                        name="floating-updown"
                        tag="p"
                    >
                        <v-btn
                            v-for="(btn, index) in buttons"
                            :key="btn.name"
                            style="text-transform:none !important;"
                            class="text-subtitle-2 mx-1 px-2 my-1 py-6"
                            color="secondary"
                            dark
                            outlined
                            rounded
                            @click.native="typeWordSelected = btn.value ; dialogWords = true"
                        >
                            <div style="display: flex; flex-flow: column wrap; align-items: center">
                                <span> {{ $t("purposes.textEditor.words."+btn.name+".up") }}</span>
                                <span> {{ $t("purposes.textEditor.words."+btn.name+".down") }}</span>
                            </div>
                        </v-btn>
                    </transition-group>-->
            </div>

            <!--        <purposeWordSelectDialog
            :title="wordTitle"
            :dialog="dialogWords"
            :items="wordsToSelect.filter(w => w.attributes.group === typeWordSelected)"
            @closed:dialog="addSelectedWord($event); dialogWords = false"
        />-->

            <confirm-dialog
                ref="confirm"
            />
        </v-form>
    </v-container>
</template>

<script>

import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";

/* import purposeWordSelectDialog from '@/components/purpose/purposeWordSelectDialog' */

import editorToolBar from '@/components/recognitions/form/editorToolBar'

import wordsListKey from '@/components/admin/words/wordsListKey'

import ConfirmDialog from '@/components/common/ConfirmDialog'

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
export default {
    name: 'VisualEditor',
    components: { VueEditor, /* purposeWordSelectDialog, */ ConfirmDialog },
    props: {
        typeEditor: {
            type: Number,
            default: 1
        },
        body: {
            type: String,
            default: ''
        },
        wordsToSelect: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data: function () {
        return {
            dialogWords: false,
            typeWordSelected: 1,
            quill: {},
            isValid: false,
            editorSettings: {
                modules: {
                    imageDrop: true,
                    imageResize: {}

                }
            },
            customToolbar: editorToolBar,
            wordsListKey_: []
        }
    },
    computed: {
        body_: {
            get() {
                return this.body;
            },
            set(val) {
                this.$emit('update:body', val);
            }
        },
        wordTitle() {
            const wordKey = wordsListKey.find(w => w.value === this.typeWordSelected);

            const msg = this.$t("purposes.textEditor.words." + wordKey.name);

            return `${msg.up} ${msg.down}`;
        },
        /* sectionTitle() {
            return this.$t("purposes.textEditor.section.title" + this.typeEditor);
        }, */
        buttons() {
            return this.wordsListKey_/* .filter(w => w.block.split(',').includes(this.typeEditor.toString())) */;
        }
    // ...mapGetters(['role', 'cities'])
    },
    mounted() {
        const numberOfTimes = wordsListKey.length;
        const delay = 500;

        for (let i = 0; i < numberOfTimes; i++) {
            setTimeout(() => {
                this.wordsListKey_.push(wordsListKey[i]);
            }, delay * i);
        };
        // console.log('******* this.getImages ****');
        this.addTooltipToEditor();
    },
    methods: {
        /* showDialog() {
            this.$refs.confirm.open(
                this.sectionTitle,
                this.$t("purposes.textEditor.section.help" + this.typeEditor),
                { noOk: true });
        }, */
        addTooltipToEditor() {
            const toolbarTooltips = {
                font: 'Select a font',
                size: 'Select a font size',
                header: 'Select the text style',
                bold: 'Bold',
                italic: 'Italic',
                underline: 'Underline',
                strike: 'Strikethrough',
                color: 'Select a text color',
                background: 'Select a background color',
                script: {
                    sub: 'Subscript',
                    super: 'Superscript'
                },
                list: {
                    ordered: 'Numbered list',
                    bullet: 'Bulleted list',
                    check: 'Checked list'
                },
                indent: {
                    '-1': 'Decrease indent',
                    '+1': 'Increase indent'
                },
                direction: {
                    rtl: 'Text direction (right to left | left to right)',
                    ltr: 'Text direction (left ro right | right to left)'
                },
                // align: 'Text alignment',
                align: {
                    default: 'Text alignment left',
                    center: 'Text alignment center',
                    justify: 'Text alignment justify',
                    right: 'Text alignment right'
                },
                blockquote: 'Blockquote',
                'code-block': 'Code block',
                link: 'Insert a link',
                image: 'Insert an image',
                video: 'Insert an video',
                formula: 'Insert a formula',
                clean: 'Clear format',
                'add-table': 'Add a new table',
                'table-row': 'Add a row to the selected table',
                'table-column': 'Add a column to the selected table',
                'remove-table': 'Remove selected table',
                help: 'Show help'
            };

            const lang = this.$store.getters['languages/getLang'];
            if (lang !== 'en') {
                Object.keys(toolbarTooltips).map(key => {
                    let value;
                    if (typeof toolbarTooltips[key] === 'string') {
                        value = 'editorActions.' + key;
                        toolbarTooltips[key] = this.$t(value);
                    } else {
                        Object.keys(toolbarTooltips[key]).map(subKey => {
                            value = 'editorActions.' + key + '.' + subKey;
                            toolbarTooltips[key][subKey] = this.$t(value);
                        });
                    }
                });
            }

            const showTooltip = (which, el) => {
                let tool;
                if (which === 'button') {
                    tool = el.className.replace('ql-', '');
                } else if (which === 'span') {
                    tool = el.className.replace('ql-', '');
                    tool = tool.substr(0, tool.indexOf(' '));
                }
                if (tool) {
                    // if element has value attribute.. handling is different
                    // buttons without value
                    if (el.value === '') {
                        if (toolbarTooltips[tool]) {
                            if (toolbarTooltips[tool].default) {
                                el.setAttribute('title', toolbarTooltips[tool].default);
                            } else { el.setAttribute('title', toolbarTooltips[tool]); }
                        }
                    } else if (typeof el.value !== 'undefined') { // buttons with value
                        if (toolbarTooltips[tool][el.value]) { el.setAttribute('title', toolbarTooltips[tool][el.value]); }
                    } else { el.setAttribute('title', toolbarTooltips[tool]); }// default
                }
            };

            const toolbarElement = document.querySelector('.ql-toolbar');
            if (toolbarElement) {
                const matchesButtons = toolbarElement.querySelectorAll('button');
                for (const el of matchesButtons) {
                    showTooltip('button', el);
                }
                // for submenus inside
                const matchesSpans = toolbarElement.querySelectorAll('.ql-toolbar > span > span');
                for (const el of matchesSpans) {
                    showTooltip('span', el);
                }
            }
        },
        focusQuill: function (event, id, focused = true) {
            this.quill = focused ? event : {};
        },
        addSelectedWord(value) {
            if (!value) return;

            let inserted = false;
            try {
                if (this.quill && this.quill.getSelection) {
                    const selection = this.quill.getSelection();

                    if (selection && (selection.index || selection.index === 0)) {
                        // for add space before if is necessary
                        let text = this.quill.getText(selection.index - 1, 1);
                        let spaceBefore = '';
                        if (text.length && text[0].charCodeAt(0) !== 32 && text[0].charCodeAt(0) !== 10) { // 32 is space, 10 is \n
                            spaceBefore = ' ';
                        }

                        // for add space after if is necessary
                        text = this.quill.getText(selection.index, 1);
                        let spaceAfter = '';
                        if (text.length && text[0].charCodeAt(0) !== 32 && text[0].charCodeAt(0) !== 10) { // 32 is space, 10 is \n
                            spaceAfter = ' ';
                        }

                        this.quill.insertText(selection.index, spaceBefore + value + spaceAfter);
                        inserted = true;
                    }
                }
            } catch (e) {
                console.log("Error ", e);
            };

            if (!inserted) {
                if (this.body_ && this.body_.endsWith('</p>')) {
                    let body = this.body_;

                    const lastP = body.lastIndexOf('<p>');
                    if (lastP !== -1) {
                        body = body.slice((lastP + "<p>".length), body.length - "</p>".length);

                        if (!body.endsWith(' ')) {
                            body += " ";
                        }
                        this.body_ = body + value;// body ? body + " " + value : value;
                    } else {
                        this.body_ += value;
                    }
                } else {
                    this.body_ += value;
                }
            }
        }
    }
}
</script>

<style >
/*iframe.ql-video {
  width: 500px !important;
  height: 500px !important;
}*/

.quill-editor iframe {
  pointer-events: none;
}

.editorPurpose .ql-editor {
  min-height: 100px !important;
}
</style>
