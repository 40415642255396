<template>
    <v-overlay
        z-index="99"
        :value="overlay"
        opacity="0.95"
    >
        <div
            class="text-right mr-4"
            @click.stop="$emit('update:overlay', false)"
        >
            <v-icon
                color="#fff"
            >
                mdi-close
            </v-icon>
        </div>

        <div style="display: flex; justify-content: center; max-width:100vw; max-height:480px">
            <video
                style="width:90vw; max-width:854px; height: auto;"
                controls
                autobuffer
                :poster="poster"
            >
                <slot />
                Su navegador no soporta Videos HTML
            </video>
        </div>
    </v-overlay>
</template>

<script>
export default {
    name: "OverlayFullScreenVideo",
    props: {
        overlay: {
            type: Boolean,
            required: true
        },
        poster: {
            type: String,
            required: true
        }
    }
    /* computed: {
        overlay_: {
            get() {
                return this.overlay;
            },
            set(val) {
                this.$emit('update:overlay', val);
            }
        }
    } */
}
</script>

<style scoped>

</style>
